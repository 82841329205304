import { Validator } from "vee-validate"

const isCorrectPassword = (value) => {
  let test =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/.test(
      value
    )
  // console.log(value, test);
  return test
}
Validator.extend("correctPassword", {
  validate: isCorrectPassword,
  getMessage: () =>
    "Password should have minimum 8 chars, at least 1 uppercase letter, at least 1 number char and 1 special char.",
})
