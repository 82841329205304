<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card>
          <v-toolbar>
            <v-toolbar-title>Change password</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <template v-if="isHashValid && !isPasswordReseted">
            <v-card-text>
              <v-form data-vv-scope="form-reset-password">
                <ul v-if="errors_api" class="error-messages">
                  <li v-for="(v, k) in errors_api" :key="k">{{ v }}</li>
                </ul>
                <v-alert
                  :value="true"
                  type="error"
                  v-if="errors.has('form-reset-password.general_error_field')"
                >
                  {{ errors.first("form-reset-password.general_error_field") }}
                </v-alert>

                <v-alert
                  v-if="havePasswords && !equalPasswords && showNotEqualAlert"
                  border="left"
                  colored-border
                  type="error"
                  elevation="2"
                >
                  Entered passwords not equal
                </v-alert>
                <div class="formRow">
                  <div class="formRow-fields">
                    <v-text-field
                      v-model="passwordFirst"
                      data-vv-name="password-first"
                      label="New password"
                      :type="showPass ? 'text' : 'password'"
                      hint="Password should have minimum 8 chars, at least 1 uppercase letter, at least 1 number char and 1 special char"
                      data-vv-validate-on="change"
                      outlined
                      data-testid="password-first"
                    ></v-text-field>
                    <v-text-field
                      :disabled="!passwordFirst"
                      v-model="password"
                      v-validate="'required'"
                      :error-messages="
                        equalPasswordMessage ||
                        errors.collect('form-reset-password.password')
                      "
                      data-vv-name="password"
                      label="Repeat password"
                      :type="showPass ? 'text' : 'password'"
                      hint="Password should have minimum 8 chars, at least 1 uppercase letter, at least 1 number char and 1 special char"
                      data-vv-validate-on="change"
                      outlined
                      data-testid="password-second"
                    ></v-text-field>
                  </div>
                  <div class="formRow-button">
                    <v-btn
                      v-if="$vuetify.breakpoint.mdAndUp"
                      :disabled="!passwordFirst"
                      text
                      block
                      color="primary"
                      @click="showPass = !showPass"
                    >
                      <v-icon v-if="showPass">mdi-eye</v-icon>
                      <v-icon v-else>mdi-eye-off</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                block
                @click="submit"
                color="primary"
                :disabled="!equalPasswords"
                data-testid="btn-change-password"
              >
                Change password
              </v-btn>
            </v-card-actions>
          </template>

          <template v-if="isPasswordReseted">
            <v-card-text>
              <v-alert :value="true" color="success">
                <v-icon>mdi-alert-circle-outline</v-icon>
                Password changed.
              </v-alert>
            </v-card-text>
          </template>

          <template v-if="!isHashValid && isHashAsked">
            <v-card-text>
              <v-alert :value="true" color="error">
                <v-icon>mdi-alert-circle-outline</v-icon>
                Invalid password recovery link.
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-btn text block color="primary" to="/auth/password/reset"
                >Request link again</v-btn
              >
            </v-card-actions>
          </template>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import "@/validators/password/password-validator"
import { mapState } from "vuex"
import GeneralMixin from "@/mixins/general"

export default {
  mixins: [GeneralMixin],

  data() {
    return {
      hash: null,
      passwordFirst: "",
      password: "",
      isHashValid: true,
      isHashAsked: false,
      isPasswordReseted: false,
      showNotEqualAlert: false,
      dictionary: {
        attributes: {
          email: "E-mail Address",
        },
        custom: {
          //                password: {
          //                  required: () => 'Name can not be empty',
          //                },
        },
      },
      showPass: false,
    }
  },

  mounted() {
    let self = this
    this.$validator.localize("en", this.dictionary)
    this.hash = this.$route.params.reset_hash
    let data = {
      slug: self.hash,
    }
    self.$store
      .dispatch("resetPasswordCheckHash", data)
      .then((result) => {
        self.isHashValid = result.data.data.exists
        self.isHashAsked = true
      })
      .catch((error) => {
        self.isHashValid = false
        self.isHashAsked = true
        self.handleValidationErrors(error.response, "form-reset-password")
      })
    self.isHashAsked = true
  },

  methods: {
    submit() {
      let self = this
      let data = {
        slug: self.hash,
        data: {
          password: self.password,
        },
      }
      if (!self.equalPasswords) {
        self.showNotEqualAlert = true
        setTimeout(() => {
          self.showNotEqualAlert = false
        }, 4000)
      } else {
        self.$validator.validateAll("form-reset-password").then((result) => {
          if (result) {
            self.$store
              .dispatch("changePasswordForgot", data)
              .then((result) => {
                if (result && result.status === 200) {
                  self.isPasswordReseted = true
                  setTimeout(() => {
                    self.$router.push({ name: "login" }).catch((err) => {})
                  }, 1000)
                } else {
                  self.handleValidationErrors(
                    result.response,
                    "form-reset-password"
                  )
                }
              })
              .catch(({ response }) => {
                self.handleValidationErrors(response, "form-reset-password")
              })
          }
        })
      }
    },
  },

  computed: {
    ...mapState({
      errors_api: (state) => state.auth.errors,
    }),
    havePasswords() {
      if (this.passwordFirst && this.password) {
        return true
      }
      return false
    },
    equalPasswords() {
      if (this.passwordFirst && this.password) {
        if (this.passwordFirst === this.password) {
          return true
        }
        return false
      }
      return false
    },
    equalPasswordMessage() {
      if (this.passwordFirst && this.password) {
        if (this.equalPasswords) {
          return null
        } else {
          return "Passwords must be equal"
        }
      }
      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.formRow {
  display: flex;

  .formRow-fields {
    flex: 1 1 auto;
    padding-right: 12px;
  }

  .formRow-button {
    flex: 0 0 90px;
    display: flex;
    position: relative;
    justify-content: stretch;
    flex-direction: column;
    margin-bottom: 30px;

    .v-btn {
      flex: 1 1 auto;
    }
  }
}
</style>
